import './reset.css';
import React from 'react';
import { PageProps } from 'gatsby';
import { Global } from '@emotion/react';
import { SkipNavContent, SkipNavLink } from '@reach/skip-nav';
import Header from '../components/header';
import Footer from '../components/footer';
import '@reach/skip-nav/styles.css';
import FadeTransition from '../components/animations/transitions/fade-transition';
import Provider from '../components/primitives/provider';
import generateRootStyles from '../utils/generate-root-styles';
import GridContextProvider from '../components/primitives/grid/context';
import 'focus-visible/dist/focus-visible';
import './theme.css';
import './fonts.css';
import ContactOverlay from '../components/contact/overlay';
import HeaderProvider from '../components/menus/header-context';
import DrawerProvider from '../components/menus/drawer-context';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Layout = ({ children, location }: PageProps): React.ReactElement => (
    <Provider>
        <HeaderProvider>
            <GridContextProvider
                breakpoints={{
                    dt: { query: '@media screen', columns: 12, gap: 24 },
                    tb: { query: '@media (max-width: 1200px)', columns: 12, gap: 24 },
                    mb: { query: '@media (max-width: 767px)', columns: 4, gap: 16 },
                }}
                maxWidth={1440}
                desktopFirst
            >
                <DrawerProvider>
                    <Global
                        styles={generateRootStyles({
                            smallScreenSize: 320,
                            largeScreenSize: 1440,
                            backgroundColor: '#fff',
                            textColor: '#000',
                        })}
                    />
                    <SkipNavLink contentId="skip-nav" />
                    <div className="__audal_labs" css={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>
                        <Header />
                        <div css={{
                            display: 'flex',
                            width: '100%',
                            height: '100%',
                            flexGrow: 1,
                            position: 'relative',
                        }}
                        >
                            <div
                                css={{
                                    display: 'flex',
                                    width: '100%',
                                    transition: '0.2s',
                                    flexGrow: 1,
                                }}
                            >
                                <SkipNavContent css={{ width: '100%', display: 'flex', flexDirection: 'column' }} id="skip-nav" as="main">
                                    {location.pathname.includes('/contact') ? <ContactOverlay /> : (
                                        <>
                                            {children}
                                            <Footer />
                                        </>
                                    )}
                                </SkipNavContent>
                            </div>
                        </div>
                    </div>
                </DrawerProvider>
            </GridContextProvider>
        </HeaderProvider>
    </Provider>
);

export default Layout;
