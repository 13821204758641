import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Row from './primitives/grid/row';
import Col from './primitives/grid/col';
import Container from './primitives/grid/container';
import Menu from './filtering/menu';
import { useHeaderContext } from './menus/header-context';

const Footer: React.FC = () => {
    const {
        wp: {
            acfOptionsOptions: {
                siteOptions: {
                    address,
                    email,
                    linkedin,
                    phoneNumber,
                    twitter,
                },
            },
        },
        wpMenu: {
            menuItems: {
                nodes: menuItems,
            },
        },
    } = useStaticQuery(graphql`
        {
            wp {
                acfOptionsOptions {
                    siteOptions {
                        address
                        email
                        linkedin
                        phoneNumber
                        twitter
                    }
                }
            }
            wpMenu(name: { eq: "Footer" }) {
                menuItems {
                    nodes {
                        label
                        url
                    }
                }
            }
        }
    `);

    const { prepare, show } = useHeaderContext();

    if (prepare || show) return null;

    return (
        <div css={{
            width: '100%',
            transition: '0.4s',
            '[aria-hidden="true"] &': {
                paddingLeft: 'min(calc(37.5rem - 10vw), 34vw)',
                '@media (max-width: 1200px)': {
                    paddingLeft: '0',
                },
            },
        }}
        >
            <Container css={{
                marginTop: '40px',
                marginBottom: '30px',
            }}
            >
                <Menu hideSelector />
            </Container>
            <footer>
                <Container>
                    <Row css={{
                        borderTop: '1px solid var(--color-primary-pine)',
                        paddingTop: '30px',
                        paddingBottom: '44px',
                        width: '100%',
                        fontSize: '14px',
                        lineHeight: '20px',
                    }}
                    >
                        <Col
                            breakpoints={{ dt: { span: 3, align: 'start', justify: 'end' }, tb: { span: 3, justify: 'start' }, mb: { span: 12 } }}
                            css={{ gridRow: '1', '@media (max-width: 767px)': { marginBottom: '24px' } }}
                        >
                            <a href={`tel:${phoneNumber as string}`}>{phoneNumber}</a>
                            <a css={{ wordBreak: 'break-all' }} href={`mailto:${email as string}`}>{email}</a>
                        </Col>
                        <Col
                            breakpoints={{ dt: { span: 3, align: 'start', justify: 'end' }, tb: { span: 3, justify: 'start' }, mb: { span: 6 } }}
                            css={{ gridRow: '1', '@media (max-width: 767px)': { marginBottom: '24px', gridRow: '2' } }}
                        >
                            <span css={{ wordBreak: 'break-word' }} dangerouslySetInnerHTML={{ __html: address }} />
                        </Col>
                        <Col
                            breakpoints={{ dt: { span: 2, align: 'start', justify: 'end' }, tb: { span: 2, justify: 'start' }, mb: { span: 6, align: 'end' } }}
                            css={{ gridRow: '1', '@media (max-width: 767px)': { marginBottom: '24px', gridRow: '2' } }}
                        >
                            {linkedin && (
                                <a href={linkedin}>
                                    <span>Follow us on </span>
                                    <br />
                                    <span>LinkedIn</span>
                                </a>
                            )}
                            {twitter && <a href={twitter}>Twitter</a>}
                        </Col>
                        <Col
                            breakpoints={{ dt: { span: 2, align: 'start', justify: 'end' }, tb: { span: 2, justify: 'start' }, mb: { span: 12 } }}
                            css={{ gridRow: '1', '@media (max-width: 767px)': { gridRow: '3' } }}

                        >
                            {menuItems && menuItems.map(({ label, url }: any) => (
                                <Link to={url}>
                                    {label}
                                </Link>
                            ))}
                            <span
                                css={{
                                    display: 'none',
                                    '@media (max-width: 767px)': {
                                        display: 'block',
                                    },
                                }}
                            >
                                proUrban ©
                                {' '}
                                {new Date().getFullYear()}
                                .
                            </span>
                        </Col>
                        <Col
                            breakpoints={{ dt: { span: 2, align: 'end', justify: 'end' }, tb: { span: 2, justify: 'start' }, mb: { span: 4, justify: 'end' } }}
                            css={{ gridRow: '1', '@media (max-width: 767px)': { gridRow: '3' } }}

                        >
                            <span
                                css={{
                                    '@media (max-width: 767px)': {
                                        display: 'none',
                                    },
                                }}
                            >
                                proUrban ©
                                {' '}
                                {new Date().getFullYear()}
                                .
                            </span>
                            <a href="https://atollon.com.au">Design by Atollon</a>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </div>
    );
};

export default Footer;
