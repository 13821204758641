import React from 'react';
import FadeTransition from '../animations/transitions/fade-transition';
import { Drawer, DrawerContent } from '../primitives/drawer';

interface IDrawerContext {
    isOpen: boolean
    openDrawer: ({ jsx, onClose, onCloseButton }: { jsx: JSX.Element, onClose?: () => void, onCloseButton?: () => void }) => void
    closeDrawer: () => void
}

const DrawerContext = React.createContext<IDrawerContext>({} as any);

const DrawerProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
    const [open, setOpen] = React.useState(false);
    const [jsxHash, setJsxHash] = React.useState(0);

    const jsxRef = React.useRef<JSX.Element|null>(null);
    const onCloseRef = React.useRef<(()=>void)|null>(null);
    const onCloseButtonRef = React.useRef<(()=>void)|null>(null);

    const closeDrawer = (): void => {
        if (onCloseRef.current) {
            onCloseRef.current();
        } else {
            setTimeout(() => {
                window.history.go(-1);
            }, 500);
        }
        setOpen(false);
        jsxRef.current = null;
        onCloseRef.current = null;
    };

    const openDrawer = ({ jsx, onClose, onCloseButton }:
    { jsx: JSX.Element, onClose?: () => void, onCloseButton?: () => void }): void => {
        if (!open) {
            setOpen(true);
        }
        jsxRef.current = jsx;
        if (onClose) {
            onCloseRef.current = onClose;
        }
        if (onCloseButton) {
            onCloseButtonRef.current = onCloseButton;
        }
        if (onClose && !onCloseButton) {
            onCloseButtonRef.current = onClose;
        }
        setJsxHash(Math.random());
    };

    const value = React.useMemo(() => ({ isOpen: open, openDrawer, closeDrawer }), [open]);

    return (
        <DrawerContext.Provider value={value}>
            <Drawer
                open={open}
                onOpenChange={(e) => {
                    closeDrawer();
                }}
            >
                <DrawerContent
                    placement={{ dt: 'left', mb: 'bottom' }}
                    size="xl"
                    css={{
                        backgroundColor: 'var(--color-primary-pine)',
                    }}
                >
                    <FadeTransition
                        shouldChange={jsxHash.toString()}
                        css={{
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            width: '100%',
                        }}
                    >
                        {jsxRef.current}
                    </FadeTransition>
                </DrawerContent>
            </Drawer>
            {children}
        </DrawerContext.Provider>
    );
};

export default DrawerProvider;

export const useDrawerContext = (): IDrawerContext => React.useContext<IDrawerContext>(DrawerContext);
