import React from 'react';
import { useLocation } from '@reach/router';

interface IHeaderContext {
    show: boolean
    prepare: boolean
    title: string
    openGallery: ({ title }: { title: string }) => void
    prepareGallery: ({ title }: { title: string }) => void
    closeGallery: () => void
}

const HeaderContext = React.createContext<IHeaderContext>({} as any);

const HeaderProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
    const [gallery, setGallery] = React.useState({
        show: false,
        prepare: false,
        title: '',
    });

    const openGallery = ({ title }:
    {title: string }): void => {
        setGallery({
            show: true,
            prepare: false,
            title,
        });
    };

    const closeGallery = (): void => {
        setGallery({
            show: false,
            prepare: false,
            title: '',
        });
    };

    const prepareGallery = ({ title }: { title: string }): void => {
        setGallery({
            show: false,
            prepare: true,
            title,
        });
    };

    const { pathname } = useLocation();

    React.useEffect(() => {
        if (gallery.show) {
            setTimeout(() => {
                closeGallery();
            }, 500);
        }
    }, [pathname]);

    const value = React.useMemo(() => ({
        ...gallery,
        openGallery,
        closeGallery,
        prepareGallery,
    }), [gallery]);

    return (
        <HeaderContext.Provider value={value}>
            {children}
        </HeaderContext.Provider>
    );
};

export default HeaderProvider;

export const useHeaderContext = (): IHeaderContext => React.useContext(HeaderContext);
